import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { FaBars, FaTimes } from "react-icons/fa"
import { LanguageContext } from "../context/languageContextProvider"

const Header = ({ siteTitle }) => {
  const [open, setOpen] = React.useState(false)
  const [showBG, setShowBG] = React.useState(false)
  const [language, setLanguage] = React.useContext(LanguageContext)

  React.useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 150
      if (show) {
        setShowBG(true)
      } else {
        setShowBG(false)
      }
    }

    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  React.useEffect(() => {
    const show = window.scrollY > 310
    if (show) {
      setShowBG(true)
    } else {
      setShowBG(false)
    }
  }, [])

  return (
    <Nav showbg={+showBG}>
      <NavLanguage showbg={+showBG}>
        <button
          onClick={() => {
            setLanguage("EN")
          }}
          className={`${language === "EN" ? "lang-active" : ""}`}
        >
          English
        </button>
        <div></div>
        <button
          onClick={() => {
            setLanguage("CN")
          }}
          className={`${language === "CN" ? "lang-active" : ""}`}
        >
          中文
        </button>
      </NavLanguage>
      <NavMenuMobile open={+open}>
        <NavLink to="/" mobile={+true} onClick={() => setOpen(false)}>
          {language === "EN" && "Home"}
          {language === "CN" && "家"}
        </NavLink>
        <NavLink to="/about" mobile={+true} onClick={() => setOpen(false)}>
          {language === "EN" && "About Us"}
          {language === "CN" && "关于我们"}
        </NavLink>
        <NavLink to="/gallery" mobile={+true} onClick={() => setOpen(false)}>
          {language === "EN" && "Gallery"}
          {language === "CN" && "画廊"}
        </NavLink>
        <NavLink to="/videos" mobile={+true} onClick={() => setOpen(false)}>
          {language === "EN" && "Videos"}
          {language === "CN" && "视频"}
        </NavLink>
        <NavLink to="/contact" mobile={+true} onClick={() => setOpen(false)}>
          {language === "EN" && "Contact"}
          {language === "CN" && "联络"}
        </NavLink>
      </NavMenuMobile>
      <NavMenu>
        <NavLink to="/" showbg={+showBG}>
          {language === "EN" && "Home"}
          {language === "CN" && "家"}
        </NavLink>
        <NavLink to="/about" showbg={+showBG}>
          {language === "EN" && "About Us"}
          {language === "CN" && "关于我们"}
        </NavLink>
        <NavLink to="/gallery" showbg={+showBG}>
          {language === "EN" && "Gallery"}
          {language === "CN" && "画廊"}
        </NavLink>
        <NavLink to="/videos" showbg={+showBG}>
          {language === "EN" && "Videos"}
          {language === "CN" && "视频"}
        </NavLink>
        <NavLink to="/contact" showbg={+showBG}>
          {language === "EN" && "Contact"}
          {language === "CN" && "联络"}
        </NavLink>
      </NavMenu>
      {!open && <Bars onClick={() => setOpen(true)} showbg={+showBG} />}
      {open && <Cross onClick={() => setOpen(false)} showbg={+showBG} />}
    </Nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const Nav = styled.nav`
  background: ${props => (props.showbg ? "white" : "transparent")};
  height: 80px;
  display: flex;
  justify-content: center;
  z-index: 100;
  position: fixed;
  padding: 1rem;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: ${props =>
    props.showbg ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "none"};
  transition: all 0.2s ease;
`

const NavLink = styled(Link)`
  color: ${props => (props.showbg ? "#000" : "#fff")};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  z-index: 101;
  transition: all 0.2s ease;
  font-size: ${props => (props.mobile ? "2rem" : "1rem")};

  &:hover {
    color: #b4d6d4;
  }
`

const Bars = styled(FaBars)`
  display: none;
  color: ${props => (props.showbg ? "#000" : "#fff")};

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

const Cross = styled(FaTimes)`
  display: none;
  color: ${props => (props.showbg ? "#000" : "#fff")};

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const NavMenuMobile = styled.nav`
  display: none;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: all 0.2s ease;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #93b5b3;

  @media screen and (max-width: 768px) {
    display: flex;
    padding-top: ${props => (props.open ? "80px" : "0")};
    padding-bottom: ${props => (props.open ? "4rem" : "0")};
    height: ${props => (props.open ? "100vh" : "0")};
  }
`

const NavLanguage = styled.div`
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    color: ${props => (props.showbg ? "#000" : "#fff")};
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 0;
    margin: 0;
    width: max-content;

    &:hover {
      color: ${props => (props.showbg ? "#93b5b3" : "#b4d6d4")};
    }
  }

  .lang-active {
    color: ${props => (props.showbg ? "#93b5b3" : "#b4d6d4")};
  }

  div {
    height: 1rem;
    width: 2px;
    background-color: ${props => (props.showbg ? "#000" : "#fff")};
  }
`
