import React from "react"
import styled from "styled-components"
import Icon from "../images/logo.png"
import { LanguageContext } from "../context/languageContextProvider"

function Footer() {
  const [language, setLanguage] = React.useContext(LanguageContext)

  return (
    <FooterMain>
      <FooterInfo className="footer-info">
        <img src={Icon} alt="Logo" className="footer-logo" />
        <FooterLocation>
          <FooterText>The ONE Wedding Plaza</FooterText>
          <FooterText>Second Floor</FooterText>
          <FooterText>59 E Broadway</FooterText>
          <FooterText>New York, NY 10002</FooterText>
          <br />
          <FooterText>New York Bridal Inc.</FooterText>
          <FooterText>5201 7th Ave</FooterText>
          <FooterText>New York, NY 11220</FooterText>
          <br />
          <FooterText>Imperial Bridal Inc.</FooterText>
          <FooterText>42-18 Main Street</FooterText>
          <FooterText>Flushing, NY 11355</FooterText>
        </FooterLocation>
        <FooterContact>
          <FooterText>Michael Lin</FooterText>
          <FooterText>
            (412)-403-3316 <br />
            WeChat: Ji_Lin_Mike
          </FooterText>
          <br />
          <FooterText>Amos Leo:</FooterText>
          <FooterText>
            (917)-353-2841 <br />
            WeChat: Amosleo777
          </FooterText>
          <br />
          <FooterText>Chengdong Yao:</FooterText>
          <FooterText>
            (917)-803-3677 <br />
            WeChat: yaochengdong-
          </FooterText>
          <br />
          <FooterText>Lily:</FooterText>
          <FooterText>
            (917)-412-6873 <br />
            WeChat: lilywong1009
          </FooterText>
          <br />
        </FooterContact>
        <FooterLinks>
          <FooterText>
            <a href="/" className="footer-link">
              {language === "EN" && "Home"}
              {language === "CN" && "家"}
            </a>
          </FooterText>
          <FooterText>
            <a href="/about" className="footer-link">
              {language === "EN" && "About Us"}
              {language === "CN" && "关于我们"}
            </a>
          </FooterText>
          <FooterText>
            <a href="/gallery" className="footer-link">
              {language === "EN" && "Gallery"}
              {language === "CN" && "画廊"}
            </a>
          </FooterText>
          <FooterText>
            <a href="/videos" className="footer-link">
              {language === "EN" && "Videos"}
              {language === "CN" && "视频"}
            </a>
          </FooterText>
          <FooterText>
            <a href="/contact" className="footer-link">
              {language === "EN" && "Contact"}
              {language === "CN" && "联络"}
            </a>
          </FooterText>
          <FooterText>
            <a
              href="https://www.facebook.com/profile.php?id=100090315949753&mibextid=LQQJ4d"
              className="footer-link"
            >
              Facebook
            </a>
          </FooterText>
        </FooterLinks>
      </FooterInfo>
      <Copyright>&copy; Ol The ONE Wedding Plaza</Copyright>
    </FooterMain>
  )
}

export default Footer

const FooterMain = styled.footer`
  text-align: center;
  background-color: #3e454d;
  padding: 3rem;
  font-size: 0.9rem;
`

const FooterInfo = styled.div`
  color: rgb(226, 226, 226);
  text-align: left;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1000px) {
    justify-content: space-between;
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 750px) {
    flex-direction: column;
    text-align: center;
  }

  img {
    width: 5rem;
    height: 5rem;
    @media only screen and (max-width: 750px) {
      margin: 0 auto 1rem auto;
    }
  }
`

const FooterText = styled.p`
  margin-bottom: 0.3rem;
`

const Copyright = styled.p`
  color: white;
`

const FooterLocation = styled.div`
  @media only screen and (max-width: 750px) {
    margin-bottom: 1rem;
  }
`

const FooterContact = styled.div`
  @media only screen and (max-width: 750px) {
    margin-bottom: 1rem;
  }
`

const FooterLinks = styled.div`
  a {
    color: rgb(226, 226, 226);
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease;

    &:hover {
      border-bottom: 1px solid white;
    }
  }
`
